'use client';

import { useState } from 'react';
import styles from './button.module.css';

interface I_ButtonProps {
	onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
	variant?:
		| 'bgPrimary'
		| 'bgError'
		| 'bgErrorBase'
		| 'bgErrorDark'
		| 'bgOrange'
		| 'bgOrange_01'
		| 'bgWhiteOrange_01'
		| 'bgWhiteGrey300'
		| 'bgGrey300'
		| 'bgWhite'
		| 'bgCultured'
		| 'bgWhiteErrorLight'
		| 'bgWhiteErrorDark'
		| 'bgWhitePrimary'
		| 'disabled';
	color?:
		| 'clWhite'
		| 'clErrorBase'
		| 'clPrimary'
		| 'clGrey600'
		| 'clGrey700'
		| 'clGrey900'
		| 'clOrange'
		| 'clOrange_01';
	size?: 'xs' | 'xm1' | 'sm' | 'md' | 'lg' | 'xl' | 'fullWidth';
	fullWidth?: boolean;
	disabled?: boolean;
	hover?: string;
	iconLeft?: JSX.Element;
	iconRight?: JSX.Element;
	children: JSX.Element | string;
}

const Button = ({
	onClick,
	variant,
	color,
	size,
	fullWidth,
	iconLeft,
	iconRight,
	children,
	disabled,
	hover
}: I_ButtonProps) => {
	const [disable, setDisabled] = useState<boolean>(false);

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setDisabled(true);

		onClick && onClick(e);

		setTimeout(() => {
			setDisabled(false), 200;
		});
	};

	return (
		<button
			onClick={onClick && handleClick}
			className={`${styles.button} ${variant ? styles[variant] : ''}
      ${hover ? styles[hover] : ''}
      ${color ? styles[color] : ''}
      ${size ? styles[size] : ''} ${fullWidth ? styles.fullWidth : ''} ${
			size === 'xs' ? 'ltbody_sm__regular' : 'ltbody_lg__regular'
		} t_nowrap`}
			disabled={disable || disabled}>
			{iconLeft && iconLeft} {children} {iconRight && iconRight}
		</button>
	);
};

export default Button;
